import request from '@/utils/request'

// 登录方法
export function productField(data) {
    return request({
        url: '/product/field',
        method: 'post',
        data
    })
}

// 列表
export function productLst(params) {
    return request({
        url: '/product/lst',
        method: 'get',
        params
    })
}

// 删除
export function productDel(data)
{
    return request({
        url: '/product/del',
        method: 'delete',
        data
    })
}

/**
 * 菜单选项
 * @returns {AxiosPromise}
 */
export function cateOption()
{
    return request({
        url: '/cate/options',
        method: 'get',
    })
}

// 添加
export function productAdd(data) {
    return request({
        url: '/product/add',
        method: 'post',
        data
    })
}

// edit
export function productEdit(data) {
    return request({
        url: '/product/edit',
        method: 'post',
        data
    })
}

export function productInfo(params)
{
    return request({
        url: '/product/info',
        method: 'get',
        params
    })
}
