<template>
  <div class="home">
    <div class="header">
      <van-search
          @change="reload"
          v-model="search.name"
          show-action
          placeholder="请输入搜索关键词"
          input-align="center"
      >
        <template #action>
          <div @click="toUrl('/product/add')" class="search">添加</div>
        </template>
      </van-search>
      <van-tabs @click="onClick">
        <van-tab title="全部商品"></van-tab>
        <van-tab title="上架商品"></van-tab>
        <van-tab title="下架商品"></van-tab>
      </van-tabs>
    </div>
    <van-list
        class="list"
		v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
			finished-text="我是有底线的" @load="onLoadList"
    >
      <div class="card-body">
        <van-swipe-cell v-for="(item,index) in goodsList" :key="index" v-if="goodsList.length != 0">
          <van-card
              :num="item.stock"
              :price="item.price"
              :desc="item.intro"
              :title="item.name"
              class="goods-card"
              :thumb="imageUrl+item.image"
          />
          <template #right>
            <van-button v-show="!item.status" @click="changeHandle(item,'status')" square text="上架" type="success" class="card-button" />
            <van-button v-show="item.status" @click="changeHandle(item,'status')" square text="下架" type="warning" class="card-button" />
            <van-button square text="修改" @click="toUrl('/product/add',{id: item.id})" type="primary" class="card-button" />
            <van-button square text="删除" @click="del(item)" type="danger" class="card-button" />
          </template>
        </van-swipe-cell>
		<!-- <van-empty description="暂无列表" v-else/> -->
      </div>
    </van-list>
  </div>
</template>

<script>
import { productLst, productDel, productField } from "@/api/product"
import { Dialog,Toast } from 'vant';
import { toUrl } from '@/utils/tools'
import
	http
	from '../../utils/config.js'
export default {
  data(){
    return{
      state: {
        loading: true,
        finished: false
      },
      list: [],
	  imageUrl: http.imageUrl,
      search: {
        name: '',
        status: '',
        page: 1,
        limit: 15
      },
	  goodsList: [],
	  isDownLoading: false, // 下拉刷新
	  isUpLoading: false, // 上拉加载
	  upFinished: false, // 上拉加载完毕
	  offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
    }
  },
  mounted() {
  	this.search.page = 1
  	// console.log(this.search.page)
  	this.onLoad()
  },
  methods:{
    onClick(name,title) {
      switch (title) {
        case '全部商品' :
          this.search.status=null
          this.reload()
          break;
        case  '上架商品':
          this.search.status=1
          this.reload()
          break;
        case '下架商品':
          this.search.status=0
          this.reload()
          break;
      }
    },
    reload() {
      this.goodsList = []
      this.list = []
      this.search.page = 1
      this.search.limit = 15
      this.onLoad()
    },
    onLoad(){
		// this.isUpLoading = true
		productLst(this.search).then((res)=>{
		 console.log(res)
		 const rows = res.data.data
		 // console.log(rows.length)
		 if (rows.length === 0) {
		 	// 加载结束
		 	this.upFinished = true
		 	return
		 }
		 if (rows.length < this.limit) {
		 	// 最后一天不足10条的情况
		 	this.upFinished = true
		 }
		 // 处理数据 
		 if (this.search.page === 1) {
		 	this.goodsList = rows
		 } else {
		 	this.goodsList = this.goodsList.concat(rows)
		 }
		})
    },
    add(){
      this.$router.push('/home/add')
    },
    edit(row){
      this.$router.push({path:'/home/edit', query: {row:row}})
    },
    del(item){
      Dialog.confirm({
        message: '确定删除'+item.name+'商品吗？',
      }).then(() => {
        productDel({'id':item.id}).then((res)=>{
          Toast.success(res.msg);
          this.reload()
        })
      })
    },
    toUrl(path, param = null) {
      toUrl(path,param)
    },
    changeHandle(item,field) {
      productField({'id':item.id,'field':field,'value':item.status ? 0 : 1}).then((res)=>{
        Toast.success(res.msg);
        this.reload()
      })
    },
	onLoadList(){
		console.log("加载")
		this.search.page++;
		this.onLoad()
	}
  },

   watch:{ 
      $route(to, from) {
		console.log(to)
		console.log(from)
      }
    }
}
</script>
<style lang="less" scoped>
.home {
  min-height: calc(100vh);
  .search {
    width: 60px;
    text-align: center;
    border: 1px solid #07c160;
    border-radius: 10px;
    color: #07c160;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  .list {
    margin: 0 .5rem;
    border-radius: .5rem;
    padding: 7rem 0 1rem 0;
  }
  .card-body {
    border-radius: .5rem;
    overflow: hidden;
  }
  .card-button {
    height: 100%;
  }
}
</style>

